import React from "react";

export default function FormCheckbox({
  name,
  value,
  setValue,
  required = false,
  label = null,
}) {
  return (
    <fieldset className="input --checkbox">
      <input
        required={required}
        onChange={() => setValue(!value)}
        type="checkbox"
        id={name}
        checked={value}
      ></input>
      <label htmlFor={name}>{label ? label : name}</label>
    </fieldset>
  );
}
