import React from "react";
import {  useSelector } from "react-redux";
import { useAuth } from "../Context/AuthProvider";
import { useInfromation } from "../Context/InfromationProvider";
import {

  COOKIE_CONSENT,
  COOKIE_CONSENT_ACCEPT,
  COOKIE_CONSENT_DECLINE,
  YOUR_ACCOUNNT_IS_BLOCKED,
} from "../language";
import AlertMessage from "./AlertMessage";
import CookieConsent from "react-cookie-consent";
import DineInBar from "./DineInBar";


export default function MessagesBar() {
  const isDineIn = useSelector((state) => state.order._dine_in);
  const { information } = useInfromation();
  const { currentUser, isLoggedIn } = useAuth();
  
 

  return (
    <>
      <CookieConsent
        location="bottom"
        declineButtonText={COOKIE_CONSENT_DECLINE}
        buttonText={COOKIE_CONSENT_ACCEPT}
        enableDeclineButton
      >
        {COOKIE_CONSENT}
      </CookieConsent>
      {isDineIn && (
        <DineInBar />
      )}
      {isLoggedIn && currentUser && currentUser.blocked && (
        <AlertMessage variant="error" message={YOUR_ACCOUNNT_IS_BLOCKED} />
      )}
      {information.is_super_closed && (
        <AlertMessage
          variant="error"
          message={information.is_super_closed_message}
        />
      )}
    </>
  );
}
