import { useInfromation } from "Context/InfromationProvider";
import React, { useEffect, useState } from "react";
import { FaFacebookF, FaInstagram, FaYoutube } from "react-icons/fa";
import { SiTiktok } from "react-icons/si";

export default function SocialLinks() {
  const { information, loading } = useInfromation();

  const [Fb, setFb] = useState({});
  const [Yt, setYt] = useState({});
  const [Tt, setTt] = useState({});
  const [In, setIn] = useState({});

  useEffect(() => {
    if (information.social_links) {
      setFb(
        information.social_links.find((link) => link.network === "Facebook")
      );
      setYt(
        information.social_links.find((link) => link.network === "Youtube")
      );
      setTt(information.social_links.find((link) => link.network === "Tiktok"));
      setIn(
        information.social_links.find((link) => link.network === "Instagram")
      );
    }
  }, [information]);

  if (loading || !Fb || !Yt || !Tt || !In) {
    return <></>;
  }
  return (
    <div className="social-links">
      {Fb.url && (
        <div className="__link">
          <a href={Fb.url} target="_blank" rel="noreferrer">
            <FaFacebookF />
          </a>
        </div>
      )}
      {Yt.url && (
        <div className="__link">
          <a href={Yt.url} target="_blank" rel="noreferrer">
            <FaYoutube />
          </a>
        </div>
      )}
      {Tt.url && (
        <div className="__link">
          <a href={Tt.url} target="_blank" rel="noreferrer">
            <SiTiktok />
          </a>
        </div>
      )}
      {In.url && (
        <div className="__link">
          <a href={In.url} target="_blank" rel="noreferrer">
            <FaInstagram />
          </a>
        </div>
      )}
    </div>
  );
}
