import React, { useEffect, useState } from "react";
import ValidationError from "./ValidationError";

export default function FormInput({
  name,
  value,
  setValue,
  placeholder = "",
  type = "text",
  required = true,
  className = "",
  nolable = false,
  maxLength = 45,
  error = "",
  attrib = "",
  ...props
}) {
  const [localValue, setLocalValue] = useState('');
  useEffect(() => {
    setLocalValue(value);
  }, [value]);
  return (
    <fieldset className={`input ${className}`}>
      {!nolable && <label htmlFor={name}>{name}</label>}
      <input
      {...props}
        required={required}
        type={type}
        id={name}
        value={localValue}
        placeholder={placeholder}
        onChange={({ target }) => {
          setLocalValue(target.value);
          setValue(target.value, attrib);
        }}
        maxLength={maxLength}
      ></input>
      <ValidationError error={error} />
    </fieldset>
  );
}
