import React from "react";
import {ImSpoonKnife} from "react-icons/im";
const Loading = () => {
  return (
    <div className="Loading">
      <span className="__spinner"><ImSpoonKnife /></span>
    </div>
  );
};

export default Loading;
