import React, { createContext, useContext, useEffect, useState } from "react";
import useFetch from "use-http";
import { domain } from "../constants";
import { useInfromation } from "./InfromationProvider";
const ScheduleContext = createContext();

export default function ScheduleProvider({ children }) {
  const [schedule, setSchedule] = useState([]);
  const [schedule2, setSchedule2] = useState([]);
  const { get, response, loading, error } = useFetch(domain);

  const { information } = useInfromation();
  useEffect(() => {
    (async () => {
      const fetchedInfo = await get("settings/opening_schedule/");
      if (response.ok) setSchedule(fetchedInfo);
    })();
    (async () => {
      const fetchedInfo = await get("settings/opening_schedule2/");
      if (response.ok) setSchedule2(fetchedInfo);
    })();
    // eslint-disable-next-line
  }, []);

  const checkDate = (value, type = 0) => {
    const selectedWeekday = value.getDay() === 0 ? 7 : value.getDay();

    const isOpen = schedule.filter(
      (row) => row.weekday === selectedWeekday && +row.opening_type === 1
    )[0];

    if (!isOpen) {
      return {
        days: [undefined],
        ok: false,
      };
    }

    const day1 = schedule.filter(
      (row) =>
        row.weekday === selectedWeekday &&
        +row.opening_type === (information.expanded_time ? type + 1 : 1)
    )[0];
    const day2 = schedule2.filter(
      (row) =>
        row.weekday === selectedWeekday &&
        +row.opening_type === (information.expanded_time ? type + 1 : 1)
    )[0];

    const time = value.toTimeString();
    let ok = day1 !== undefined;
    const days = [day1];

    if (information.two_schedules && day1) {
      days.push(day2);
      ok =
        ok &&
        ((time > day1.from_time && time < day1.to_time) ||
          (time > day2.from_time && time < day2.to_time));
    } else {
      ok = ok && time > day1.from_time && time < day1.to_time;
    }

    return {
      days,
      ok,
    };
  };

  return (
    <ScheduleContext.Provider
      value={{ checkDate, schedule, schedule2, loading, error }}
    >
      {children}
    </ScheduleContext.Provider>
  );
}

export const useSchedule = () => useContext(ScheduleContext);
