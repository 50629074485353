import React, { createContext, useContext, useEffect, useState } from "react";
import useFetch from "use-http";
import { domain } from "../constants";
const OpeningContext = createContext();

export default function OpeningProvider({ children }) {
  const [isOpen, setIsOpen] = useState(false);
  const { get, response, loading, error } = useFetch(domain);

  useEffect(() => {
    (async () => {
      setTimeout(async () =>  {
        const fetchedInfo = await get("settings/is_restaurant_open/");
        if (response.ok) setIsOpen(fetchedInfo["open"]);  
      }, 200);
      
    })();
    // eslint-disable-next-line 
  }, []);

  return (
    <OpeningContext.Provider value={{  isOpen, loading, error }}>
      {children}
    </OpeningContext.Provider>
  );
}

export const useOpen = () => useContext(OpeningContext);
