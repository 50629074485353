export const domain =
  process.env.NODE_ENV === "production"
    ? window.origin + "/api"
    : process.env.REACT_APP_API;
export const host =
  process.env.NODE_ENV === "production"
    ? window.origin 
    : process.env.REACT_APP_HOST;

export const ADD_ITEM = "ADD_ITEM";
export const REMOVE_ITEM = "REMOVE_ITEM";
export const SET_ATTRIBS = "SET_ATTRIB";
export const CLEAR_ADDONS = "CLEAR_ADDONS";
export const CLEAR_DELIVERY= "CLEAR_DELIVERY";
