import React from "react";
import { HashLink as Link } from "react-router-hash-link";
import { FaBars } from "react-icons/fa";
import { useInfromation } from "../../Context/InfromationProvider";
import { useOpen } from "../../Context/OpeneingProvider";
import {
  MENU_LINK,
  TRACK_ORDER_LINK,
  RESTAURANT_STATUS_CLOSE,
  RESTAURANT_STATUS_OPEN,
  LOGIN_LINK,
  ACCOUNT,
  REVIEWS_PAGE_TITLE,
  TABLE_RES,
} from "../../language";
import { host } from "../../constants";
import { useAuth } from "../../Context/AuthProvider";
const Navbar = ({ toggle }) => {
  const { information } = useInfromation();
  const { isOpen, loading, error } = useOpen();
  const { isLoggedIn } = useAuth();

  return (
    <>
      {!loading && (
        <div className="nav-wrapper">
          <nav className="__nav">
            <div className="__container">
              <div className="__left">
                <Link className="__logo-wrapper" to="/#root">
                  <img
                    className="__logo"
                    src={host + information["logo"]}
                    alt="logo"
                  />
                  <span className="__title">{information["name"]}</span>
                </Link>
                <ul className="__menu">
                  <li className="__item">
                    <Link className="__link" to="/menu#root">
                      {MENU_LINK}
                    </Link>
                  </li>
                  <li className="__item">
                    <Link className="__link" to="/track/#root">
                      {TRACK_ORDER_LINK}
                    </Link>
                  </li>
                  {information.allow_users && information.allow_user_reviews && (
                    <li className="__item">
                      <Link className="__link" to="/reviews/#root">
                        {REVIEWS_PAGE_TITLE}
                      </Link>
                    </li>
                  )}
                  {information.table_reservation && (
                    <li className="__item">
                      <Link className="__link" to="/reservation/#root">
                        {TABLE_RES}
                      </Link>
                    </li>
                  )}
                </ul>
              </div>
              <div className="__mobile-icon" onClick={toggle}>
                <FaBars className=".__menu-icon" />
              </div>

              <div className="__right">
                {information.allow_users && !isLoggedIn && (
                  <nav className="__btn">
                    <Link className="__btn-link" to="/login#root">
                      {LOGIN_LINK}
                    </Link>
                  </nav>
                )}
                {isLoggedIn && (
                  <>
                    <nav className="__btn">
                      <Link to="/account" className="__btn-link">
                        {ACCOUNT}
                      </Link>
                    </nav>
                  </>
                )}
                {!loading && !error && (
                  <nav className="__btn">
                    <Link to="#work-hours">
                      <div
                        className={`__btn-link ${isOpen ? "--green" : "--red"}`}
                      >
                        {isOpen
                          ? RESTAURANT_STATUS_OPEN
                          : RESTAURANT_STATUS_CLOSE}
                      </div>
                    </Link>
                  </nav>
                )}
              </div>
            </div>
          </nav>
        </div>
      )}
    </>
  );
};

export default Navbar;
