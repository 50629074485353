import "animate.css";
import "react-responsive-modal/styles.css";
import "./styles/App.scss";
import React, { useState, Suspense } from "react";

import { Helmet } from "react-helmet";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { useInfromation } from "./Context/InfromationProvider";
import { useMenu } from "./Context/MenuProvider";
import { GrVmMaintenance } from "react-icons/gr";
import Footer from "./partials/Footer";
import GuestRoute from "./partials/GuestRoute";
import Loading from "./partials/Loading";
import MessagesBar from "./partials/MessagesBar";
import Navbar from "./partials/Navbar";
import ProtectedRoute from "./partials/ProtectedRoute";
import Sidebar from "./partials/Sidebar";
import ClipLoader from "react-spinners/ClipLoader";
import Jobs from "./routes/Jobs/index";
import Reservation from "routes/Reservation";
import SEO from "routes/SEO";
import OrderProvider from "Context/OrderProvider";
import MaintenanceWatcher from "Context/MaintenanceWatcher";

const Home = React.lazy(() => import("./routes/Home"));
const Account = React.lazy(() => import("./routes/Account"));
const Confirm = React.lazy(() => import("./routes/Confirm"));
const Login = React.lazy(() => import("./routes/Login"));
const Menu = React.lazy(() => import("./routes/Menu"));
const Reviews = React.lazy(() => import("./routes/Reviews"));
const ResetPassword = React.lazy(() => import("./routes/ResetPassword"));
const Scan = React.lazy(() => import("./routes/Scan"));
const Signup = React.lazy(() => import("./routes/Signup"));
const Track = React.lazy(() => import("./routes/Track"));

function App() {
  const { information, loading } = useInfromation();
  const { loading: menuLoading } = useMenu();

  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const [maintenanceMode, setMaintenanceMode] = useState(false);

  return (
    <>
      <Helmet>
        <title>{information["name"]}</title>

        <script async src={information.gscript_srs}></script>
        <script>{`${information.gscript}`}</script>

        <meta
          name="keywords"
          content={`${information.name} 
          ,${information.slogan} 
          ,${information.country}  
          ,${information.city}  
          ,${information.cuisine} 
          ,${information.street}`}
        />
        <meta name="description" content={`${information.seo}`} />
        <link rel="icon" href={information.logo} />
      </Helmet>

      <MaintenanceWatcher setMaintenanceMode={setMaintenanceMode} />
      {maintenanceMode === 1 && (
        <div className="maintenance">
          <GrVmMaintenance />
          <div className="__message">{information.maintenance_msg}</div>
        </div>
      )}
      {!maintenanceMode && (
        <>
          {loading || menuLoading ? (
            <Loading />
          ) : (
            <div className="App">
              <Router>
                <OrderProvider>
                  {isOpen && <Sidebar toggle={toggle} />}
                  <Navbar toggle={toggle} />
                  <MessagesBar />
                  <Suspense
                    fallback={
                      <div className="suspense-fallback">
                        <ClipLoader />
                      </div>
                    }
                  >
                    <Switch>
                      <Route exact path="/" component={Home} />
                      <Route exact path="/home" component={Home} />
                      <Route exact path="/menu" component={Menu} />
                      <Route exact path="/track" component={Track} />
                      <Route exact path="/scan" component={Scan} />
                      <Route exact path="/reviews" component={Reviews} />
                      <Route exact path="/seo/:slug" component={SEO} />

                      <Route
                        exact
                        path="/reservation"
                        component={Reservation}
                      />
                      <GuestRoute exact path="/signup" component={<Signup />} />
                      <GuestRoute exact path="/login" component={<Login />} />
                      <GuestRoute
                        exact
                        path="/confirm"
                        component={<Confirm />}
                      />
                      <GuestRoute
                        exact
                        path="/reset_password"
                        component={<ResetPassword />}
                      />
                      <ProtectedRoute
                        exact
                        path="/account"
                        component={<Account />}
                      />
                      <ProtectedRoute exact path="/jobs" component={<Jobs />} />
                    </Switch>
                  </Suspense>
                  <Footer />
                </OrderProvider>
              </Router>
            </div>
          )}
        </>
      )}
    </>
  );
}

export default App;
