// Global
export const ORDER_BUTTON = "Online Bestellen";
export const CURRENCY = "€";

// URLs
export const DEVELOPER_URL = "http://www.orderset.de";
export const FACEBOOK_URL = "/ ";
export const INSTAGRAM_URL = "/ ";
export const YOUTUBE_URL = "/ ";
export const LINKEDIN_URL = "/ ";
export const TWITTER_URL = "/ ";

// InfoSection.js
export const INFO_COUNTRY = "Stadt";
export const INFO_PHONE = "Telefonnummer";
export const DAYS_NAMES = [
  "Montag",
  "Dienstag",
  "Mittwoch",
  "Donnerstag",
  "Freitag",
  "Samstag",
  "Sonntag",
];
export const DAYS_NUMBERS = {
  1: "Montag",
  2: "Dienstag",
  3: "Mittwoch",
  4: "Donnerstag",
  5: "Freitag",
  6: "Samstag",
  7: "Sonntag",
};

// WorkInfo.js
export const DELIVERY_HEADING = "Wir liefern an";
export const DELIVERY_SUBTITLE = "Pünktlich frisch und schnell";
export const DELIVERY_SECTION = "Bereich";
export const DELIVERY_MIN_ORDER = "Mindestbestellwert für Lieferung";
export const DELIVERY_PRICE = "Lieferkosten";
export const OPENING_HEADING = "Öffnungszeiten";
export const OPENING_SUBTITLE = "Zeitzone";
export const OPENING_WEEKDAY = "Wochentag";
export const OPENING_FROM = "Von";
export const OPENING_TO = "Bis";

// CallToAction.js
export const CTA_TITLE_1 = "Erstellen und Passen Sie Ihre Bestellung an";
export const CTA_TITLE_2 = "Schreiben Sie Ihre Anweisungen";
export const CTA_TITLE_3 = "Wir bestätigen Ihre Bestellung";
export const CTA_TITLE_4 = "Erhalten Sie Ihre köstliche Mahlzeit";

// Navbar/index.js
export const MENU_LINK = "Speisekarte";
export const ABOUT_LINK = "Über uns";
export const TRACK_ORDER_LINK = "Bestellung verfolgen";
export const SIGNUP_LINK = "Registrieren";
export const LOGIN_LINK = "Anmelden";
export const RESTAURANT_STATUS_OPEN = "Geöffnet";
export const RESTAURANT_STATUS_OPEN_2 = "Heute ist Geöffnet";
export const RESTAURANT_STATUS_CLOSE = "Geschlossen";

// Sidebar/index.js
export const LINK_PRIVACY = "Datenschutzerklärung";
export const LINK_AGREEMENT = "AGB";
export const LINK_IMPRESSUM = "Impressum";
export const LINK_RETURN = "Widerrufsrecht";

// Footer.js
export const FOOTER_LINK_1 = "Rechtliches";
export const FOOTER_LINK_2 = "Nutzungsbedingungen";
export const FOOTER_LINK_3 = "FAQs";
export const FOOTER_LINK_4 = "Über uns";
export const FOOTER_LINK_5 = "Kontaktieren Sie uns";
export const DEVELOPER_NAME = "OrderSet.de";

// OrderList.js
export const ORDER_HEADER = "Ihre Bestellung";
export const ORDER_EMPTY_MESSAGE =
  "Es befinden sich keine Artikel in Ihrem Warenkorb.";
export const ORDER_ARE_YOU_SURE =
  "Möchten Sie diesen Artikel wirklich entfernen? ?";
export const ORDER_REMOVE = "Entfernen";
export const ORDER_EDIT = "Bearbeiten";

// Checkout
// ConfirmInfo.js
export const CHECKOUT_EMAIL_CONFIRMATION =
  "Ich möchte per E-Mail über den Status meiner Bestellung informiert werden";

export const CHECKOUT_TERMS_ACCEPTANCE_1 =
  "Mit Ihrer Bestellung akzeptieren Sie unsere";
export const RESERVATION_TERMS_ACCEPTANCE_1 =
  "Mit Ihrer Buchung akzeptieren Sie unsere";
export const CHECKOUT_TERMS_ACCEPTANCE_2 = "sowie die ";

// PersonalInfo.js
export const PERSONAL_INFO_HEADER = "Bestelldetails";
export const PERSONAL_INFO_FIRST_NAME = "Vorname";
export const PERSONAL_INFO_LAST_NAME = "Nachname";
export const PERSONAL_INFO_EMAIL = "Email";
export const PERSONAL_INFO_PHONE = "Telefonnummer";

// TopBar.js
export const TOP_BAR_RETURN = "Zurück zum Menü";

// Menu
// Meal.js
export const MEAL_PRICE_FROM = "ab";

// MobileCheckoutButton.js
export const MOBILE_CONFIRM_BUTTON = "Bestätigen";

// Modal.js
export const MODAL_BASE_PRICE = "Grundpreis";
export const MODAL_NOTES = "Kommentar hinzufügen";
export const MODAL_NOTES_PLACEHOLDER = "Zum Beispiel ohne Pfeffer ";
export const MODAL_QUANTITY = "Menge";
export const MODAL_TOTAL = "Gesamt";
export const MODAL_BUTTON = "Zur Bestellung hinzufügen";

// Sizes.js
export const SIZES_HEADER = "Größe";

// SuccessScreen.js
export const SUCCESS_SCREEN_THANKS =
  "Vielen Dank, dass Sie sich für unser Restaurant entschieden haben";
export const SUCCESS_SCREEN_MESSAGE = "Ihre Bestellung wird manuell geprüft";
export const SUCCESS_SCREEN_TRACK = "Verfolgen Sie Ihre Bestellung ";
export const SUCCESS_SCREEN_HERE = "hier";
export const SUCCESS_SCREEN_SLUG = "Dies ist Ihr Bestellcode";
export const SUCCESS_SCREEN_RETURN_TO_MENU = "Zurück zum Menü";
export const SUCCESS_SCREEN_RETURN_TO_HOME = "Zurück zum Home";

// words
export const LIMIT = "Grenze";
export const NAME = "Name";
export const EMAIL = "Email";
export const PHONE = "Telefonnummer";
export const ADDRESS = "Adresse";
export const AREA = "Liefern nach";
export const BUILDING_NO = "Hausnummer.";
export const TRACK = "Verfolgen";
export const PICKUP = "Abholen";
export const DELIVERY = "Liefern";
export const RETURN = "Zurück";
export const SEARCH = "Suche";

// validation
export const LIMIT_PRICE_NOTE = "Mindestbestellwert für Lieferung ist ";
export const OTHER_SECTION_VALIDATION_NOTE =
  "Falls Sie Ihr Gebiet nicht Finden, rufen Sie uns an";

// track
export const ORDER_NOT_FOUND = "Bestellung nicht gefunden";

// type selection
export const CONTINUE_TO_MENU = "ZUR SPEISEKARTE";
export const SEE_THE_MENU = "Speisekarte ansehen";
export const TYPE_SELECTOR_HEADING =
  "Wie möchten Sie Ihre Bestellung erhalten ?";
export const TYPE_SELECTOR_CLOSED = "Wir haben zur Zeit geschlossen";
export const SEARCH_PLACEHOLDER = "Suche nach ...";

export const ORDER_TRACK_PLACEHOLDER = "Geben Sie Ihren Bestellcode ein...";
export const CONFIRM_ORDER = "Zur Kasse";
export const DELIVERY_MIN_FREE_PRICE = "Kostenfreie Lieferung ab";
export const ORDER_TRACK_BUTTON = "Verfolgen";
export const ORDER_TITLE = "Ihre Bestellung ist";
export const ORDER_COUNTER = "Ihre Bestellung sollte fertig sein in";
export const DELIVERY_COST = "Lieferkosten";
export const POWERED_BY = "Entwickelt von";
export const CHECKOUT_BUTTON = "Jetzt Bestellen";
export const TRACK_YOUR_ORDER = "Verfolgen Sie Ihre Bestellung";
export const CHECKBOX_VALIDATION_ERROR_1 = "Sie müssen mindestens";
export const ITEMS = "Artikel";
export const FROM = "aus";
export const CHOOSE = "auswählen";
export const CHECKOUT_NOTES_PLACEHOLDER =
  "Zum Beispiel an der Gartentür Klingen ";

export const CHOOSE_YOUR_AREA = "Wählen Sie Ihren Bereich";

export const PENDING = "Ausstehend";
export const REJECTED = "Abgelehnt";
export const ACCEPTED = "Akzeptiert";
export const PENDING_DELIVERING = "Unterwegs";
export const PENDING_PICKUP = "Bereit zum Abholen";
export const DELIVERING = "Mit Fahrer Unterwegs";
export const DELIVERED = "Geliefert";
export const DONE = "Fertig";
export const CANCELLED = "Abgesagt";
export const MEAL = "Mahlzeit";
export const MEALS = "Mahlzeiten";
export const SIZE = "Größe";
export const SIZES = "Größen";
export const QUANTITY = "Menge";
export const TOTAL_PRICE = "Gesamtpreis";
export const ADDONS = "Extras";
export const PRICE = "Preis";

export const OFFERS_TITLE = "Angebote";
export const DAILY_OFFER = "Unsere Angebote";
export const DAILY_OFFER_ACCORDION_TITLE = "Aktuelle Angebote";
export const CLEAR = "Leeren";
export const PREORDER = "Vorbestellen";
export const PREORDER_HEADING = "Wann möchten Sie ihre Bestellung erhalten";
export const PREORDER_HEADING_2 = "Vorbestellen ist Verfügbar";
export const PREORDER_DATETIME_PLACEHOLDER =
  "Wählen Sie das Datum und die Uhrzeit Ihrer Bestellung";
export const ASAP = "Schnellstmöglich";

// Account
export const USERNAME = "Benutzername";
export const USERNAME_PLACEHOLDER = "Bitte Benutzername oder E-Mail eingeben ";
export const PASSOWRD = "Passwort ";
export const CONFIRM_PASSWORD = "Passwort wiederholen ";
export const PASSOWRD_PLACEHOLDER = "Bitte Passwort eingeben";
export const CONFIRM_PASSWORD_PLACEHOLDER = "Passwort erneut eingeben";
export const LOGIN_ERROR = "Falsches Passwort oder E-Mail";
export const REMEMBER_ME = "Angemeldet bleiben";
export const USERNAMEOR_EMAIL = "Benutzername oder E-Mail";
export const SIGNUP_MESSAGE = "Neu bei Uns? Jetzt registrieren ";
export const LOGIN_MESSAGE = "Haben Sie bereits ein Konto? ";
export const SIGNUP = "Registrieren";
export const FORGOT_PASSWORD = "Passwort vergessen?";
export const LOGIN = "Anmelden";
export const LOGOUT = "Abmelden";
export const NEW_PASSWORD = "Neues Passwort";
export const OLD_PASSWORD = "Altes Passwort";
export const CHANGE_PASSSWORD = "Passwort ändern";
export const PASSWORD_CONFIRMATION_ERROR = "Bitte überprüfen Sie, ob die Passwort-Wiederholung mit dem eingegebenen Passwort übereinstimmt.";
export const PLEASE_WAIT = "Bitte warten Sie";
export const RSENED_CONFIRAMTION_EMAIL = "Bestätigung erneut senden";
export const CHECK_YOUR_EMAIL =
  "Wir senden eine Verifizierungs-E-Mail. Bitte überprüfen Sie Ihre E-Mail";

// Account OrderHistory
export const ORDER_HISTORY_TITLE = "Ihr Bestellverlauf";
export const ORDER_HISTORY_IS_EMPTY = "Sie haben keine Bestellungen";
export const ORDER_ID = "Bestellungscode";
export const ORDER_PRICE = "Gesamtpreis";
export const ORDER_DATE = "Bestellt am";
export const ORDER_VIEW = "Ansicht";
export const ORDER_STATUS = "Status";
export const ORDER_TYPE = "TYP";
export const ORDER_PREORDER_DATE = "Datum der Vorbestellung";
export const ORDER_ADDRESS = "Adresse";
export const ORDER_PAYMENT = "Zahlung";
export const ORDERS = "Bestellungen";

// Account OrderHistory Modal
export const OPRDER_MODAL_TITLE = "Bestellung";
export const ORDER_CODE = "Bestellungscode";
export const ORDER_ITEMS = "Bestellte Artikel";

export const YOUR_ACCOUNT_IS_ACTIVATED = "Aktivierung abgeschlossen";
export const SOMETHING_WENT_WRONG = "Etwas ist schief gelaufen";
export const WELCOME = "Herzlich willkommen";
export const ACCOUNT = "Mein Konto";
export const CASH = "Bar";
export const PAYPAL = "Online";
export const RESET_PASSWORD = "Passwort zurücksetzen";
export const RESET_PASSWORD_MESSAGE =
  "Wir haben Ihnen eine E-Mail gesendet, bitte überprüfen Sie Ihre E-Mail";

export const CHECK_YOUR_EMAIL_2 = "Prüfen Sie Ihre E-Mail";
export const SAVE_CHENGES = "Änderungen speichern";
export const PAYPAL_COMPLETED = "Abgeschlossene Zahlung mit Zahlungsauftragsnummer ";
export const PAYPAL_WRANING =
  "Wenn Sie nicht weitergeleitet wurden, aktualisieren Sie die Seite.";
export const PAYPAL_ERROR = "Fehler bei der Zahlung";
export const DISALLOW_ADDING_ITEMS =
  "Sie haben Ihre Bestellung mit Paypal bezahlt, Sie können die Bestellung nicht ändern. Bitte senden Sie Ihre Bestellung ab, oder leeren Sie ihren Warenkorb";
export const DISALLOW_EDITING_MESSAGE =
  "Sie haben Ihre Bestellung mit Paypal bezahlt, Sie können die Bestellung nicht ändern. Bitte senden Sie Ihre Bestellung ab, oder leeren Sie ihren Warenkorb";
export const PAYPAL_WARNING_MESSAGE =
  "Sobald Sie mit Paypal bezahlen, wird Ihre Bestellung abgeschickt.";
export const PAYPAL_WARNING_2 = "Rufen Sie uns an, wenn ein Problem besteht";

export const DISALLOW_ORDER_MESSAGE =
  "*Bitte füllen Sie das Formular mit gültigen Informationen aus und füllen Sie die Zahlung aus, um die Bestellung abschicken zu können.";

export const YOU_MUST_LOGIN_TO_ORDER = "Sie müssen sich anmelden, um Ihre Bestellung aufzugeben";
export const CHANGE_PASSWORD_SUCCESS = "Geändertes Passwort";
export const CHANGE_PASSWORD_FAIL = "Etwas ist schief gelaufen";
export const SCAN_BTN = "QR-Code scannen ";
export const SCAN_MESSAGE = "Willkommen Sie sind auf Tisch ";
export const SCAN_BUTTON = "Zum Bestellen klicken";
export const DINE_IN = "Essen im Restaurant";
export const ENTER_TABLE_PASSCODE = "Tisch Passcode eingeben";
export const SUBMIT = "Starten";
export const SUBMIT_RESERVATION = "Jetzt Buche";
export const ERROR = "Fehler";
export const POINTS = "Ihre Punkte";
export const YOUR_ACCOUNNT_IS_BLOCKED = "Ihr Konto wurde gesperrt,bitte kontaktieren sie uns um die Fehler zu beheben";
export const ORDER_GIFTS = "Bestellungen Geschenk";
export const ORDER_GIFTS_MESSAGE = "Herzlichen Glückwunsch!";
export const ORDER_GIFTS_WARNING = "Sie können davon maximal  ";
export const GIFT = "Artikel wählen ";

// Reviews
export const REVIEWS_PAGE_TITLE = "Bewertungen";
export const REVIEWS_TYPE_FOOD = "Essen ";
export const REVIEWS_TYPE_DELIVERY = "Lieferung ";
export const REVIEWS_TYPE_ONLINE = "Online bestellen";
export const REVIEW_CREATE_TITLE = "Erzählen Sie von Ihren Erfahrungen";
export const REVIEW_USER_ANONYMOUS = "Anonym";
export const REVIEW_ANONYMOUS_CHECK = "Als Anonym posten?";
export const LOGIN_TO_REVIEW = "Anmelden, um Ihre Bewertung abzugeben";
export const YOU_REVIEW = "Ihre Bewertung";
export const YOU_HAVE_ALREADY_REVIEWD_OUT_RESTAURANT =
  "Sie haben bereits eine Bewertung für unser Restaurant abgegeben";
export const REVIEW_STARS_ERROR =
  "Sie müssen das Essen, die Lieferung und die Online-Bestellung jeweils bewerten";
export const REVIEW_THANKS = "Vielen Dank für die Bewertung unseres Restaurants, wir werden ihre Bewertung überprüfen ";
export const REVIEW_NO_REVIEWS =
  "Es liegen noch keine Bewertungen für unser Restaurant vor";
export const DISCOUNT = "Rabatt";
export const REQUIRED_FIELD = "Dieses Feld ist erforderlich";
export const GIFTS = "Geschenke";
export const COUPON = "Gutschein ";
export const COUPON_VALID = "Gutschein ist gültig";
export const COUPON_INVALID = "Gutschein ist ungültig";
export const ENTER_COUPON = "Gutschein eingeben";
export const HAVE_A_COUPON = "Gutschein einlösen";

// Hero
export const HERO_PLAY_STORE = "Play Store";
export const HERO_APP_STORE = "App Store";
export const HERO_GET_APP = "Holen Sie sich unsere App";

//Jobs
export const JOBS_PAGE_TITLE = "Jetzt bewerben und werde ein Teil von uns";
export const JOBS_APPLY_AS = "Bewerben als";
export const JOBS_APPLY_AS_PLACEHOLDER = "--bitte wählen--";
export const JOBS_SECTION_1_TITLE = "Persönliche Daten";
export const JOBS_FORM_TITLE = "Deine Bewerbung";
export const JOBS_REFER = "Anrede";
export const JOBS_SELECT_PLACEHOLDER = "--auswählen--";
export const JOBS_REFER_SELECT_1 = "Herr";
export const JOBS_REFER_SELECT_2 = "Frau";
export const JOBS_ADDRESS = "Straße - Hausnummer ";
export const JOBS_POST_CODE = "PLZ / Ort";
export const JOBS_BIRTH_DATE = "Geburtsdatum";
export const JOBS_BIRTH_PLACE = "Geburtsort";
export const JOBS_NATIONALITY = "Staatsangehörigkeit";
export const JOBS_MARTIAL = "Familienstand";
export const JOBS_TEL = "Telefon";
export const JOBS_EMAIL = "E-Mail";
export const JOBS_OWN_CAR = "Eigener PKW";
export const JOBS_YES_OPTION = "Ja";
export const JOBS_NO_OPTION = "Nein";
export const JOBS_DRIVING_LICENSE = "Führerscheinklasse";
export const JOBS_DEPLOYMENT =
  "Befinden sie sich z. Zt. in einem festen Arbeitsverhältnis?";
export const JOBS_LEARNED_JOB = "Welchen Beruf haben Sie gelernt ?";
export const JOBS_PAST_JOB = "Welchen Beruf üben Sie z. Zt. aus ?";
export const JOBS_ADD_NOTE = "Anmerkung";
export const JOBS_CONDITIONS =
  "Mit dem Absenden des Formulars bestätigen Sie, dass Sie damit einverstanden sind, dass Ihre uns zur Verfügung gestellten personenbezogenen Daten gespeichert und intern verarbeitet werden.";
export const JOBS_SUBMIT = "Bewerbung absenden";
export const JOBS_FOOTER_LINK = "Jobs";
export const JOBS_SUBMITTED_MESSAGE = "Danke für Ihre Bewerbung";
export const JOBS_NOT_AVAILBLE =
  "Leider gibt es keine freien Stellen in unserem Restaurant";

export const TABLE_RES = "Tisch-Reservierung";
export const TABLE_RES_HEADING = "Buchen Sie einen Tisch in unserem Restaurant";
export const TABLE_RES_PERSONS = "Anzahl der Personen";
export const TABLE_RES_DATE = "Einen Termin festlegen";
export const TABLE_RES_DATE_REQUIRED = "Datum ist erforderlich";
export const SUCCESS_SCREEN_TABLE_RES =
  "Vielen Dank, dass Sie bei uns gebucht haben, wir senden Ihnen eine E-Mail über den Status Ihrer Reservierung";

export const PAYMENT_HEADER = "Zahlungsart";
export const SIZE_VALIDATION = "Sie müssen Größe auswählen";
export const ADDITIONAL_INFO = "Zusätzliche Infos";
export const INFO = "Informationen";
export const PERSONAL_INFO = "Persönliche Angaben";
export const ARE_YOU_SURE = "Sind Sie sicher?";
export const SCHEDULE_CLOSED = "Ruhetag";
export const DELIVERY_HOURS = "Lieferzeiten";
export const PICKUP_HOURS = "Abholzeiten";
export const COOKIE_CONSENT =
"Wir respektieren Ihre Privatsphäre, Diese Website verwendet Cookies, um Ihnen die bestmögliche Benutzererfahrung zu bieten, indem Inhalte abrufen und Funktionen verwenden. Akzeptieren Sie unsere Verwendung von Cookies, um Ihre Erfahrung reibungslos zu gestalten..";
export const COOKIE_CONSENT_ACCEPT = 'Alle akzeptieren '
export const COOKIE_CONSENT_DECLINE = 'Alle ablehnen'

export const WE_ARE_OPEN_ON = 'Wir haben geöffnet am'
export const HOW_DOES_IT_WORK = 'Wie funktioniert es ?'

export const ERROR_FETCHING_PRICE = 'Ein Fehler ist aufgetreten, bitte aktualisieren Sie die Seite'
export const COPIED_TO_CLIPBOARD = 'Zum Kopieren anklicken '
export const STATUS_PENDING_MESSAGE = 'Wir werden die Bestellung in Kürze Zeit prüfen'
export const TIMEZONE = 'Zeitzone'
export const CLOSE = 'Beenden'
export const CALL_WAITER = 'Kellner anrufen '
