import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { useAuth } from "../Context/AuthProvider";
export default function ProtectedRoute({ component, ...props }) {
  const { isLoggedIn } = useAuth();
  const location = useLocation()
  return (
    <Route
      {...props}
      render={() => {
        if (isLoggedIn) {
          return component;
        } else {
          return (
            <Redirect
              to={{
                pathname: "/login",
                state: { referrer: location.pathname ?? '/menu' },
              }}
            ></Redirect>
          );
        }
      }}
    ></Route>
  );
}
