import React, { useEffect, useState } from "react";
import { DatePicker, TimePicker } from "react-rainbow-components";
import { useSchedule } from "Context/ScheduleProvider";
import {
  DAYS_NUMBERS,
  OPENING_FROM,
  OPENING_TO,
  RESTAURANT_STATUS_CLOSE,
  TIMEZONE,
  WE_ARE_OPEN_ON,
} from "language";
import { useInfromation } from "Context/InfromationProvider";

export default function DateTimeSelector({
  maxDate = new Date("1-1-2200"),
  callback = () => {},
  dateLabel = "",
  initialDateTime = null,
  openingType = 0,
}) {
  const [time, setTime] = useState(
    initialDateTime ? initialDateTime.toLocaleTimeString().slice(0, -3) : null
  );
  const [date, setDate] = useState(initialDateTime ?? null);
  const { information } = useInfromation();
  const [checkResult, setCheckResult] = useState([]);
  const [ok, setOk] = useState(true);
  const { checkDate } = useSchedule();

  useEffect(() => {
    if (time && date) {
      let dateTime = date.toDateString() + " " + time;
      dateTime = new Date(dateTime);

      const { days, ok } = checkDate(dateTime, openingType);
      setCheckResult(days);
      setOk(ok);
      callback({ value: dateTime, ok });
    } else if (date) {
      const { days } = checkDate(date, openingType);
      setCheckResult(days);
      setOk(ok);
    }

    // eslint-disable-next-line
  }, [time, date, openingType]);

  return (
    <div className="datetime-selector">
      <DatePicker
        label={dateLabel}
        value={date}
        onChange={setDate}
        required={true}
        minDate={new Date()}
        maxDate={maxDate}
        isCentered={false}
        locale={"de"}
        formatStyle={"large"}
      />
      <TimePicker
        disabled={checkResult.length > 0 && !checkResult[0]}
        value={time}
        onChange={setTime}
        required={true}
        isCentered={false}
        locale={"de"}
        formatStyle={"large"}
        hour24={true}
        hideLabel={true}
        error={!ok}
      />

      <p className="timezone">{TIMEZONE + " : " + information.time_zone} </p>
      {checkResult.length > 0 &&
        (!checkResult[0] ? (
          <p>{RESTAURANT_STATUS_CLOSE}</p>
        ) : (
          <>
            <p>{WE_ARE_OPEN_ON + " " + DAYS_NUMBERS[checkResult[0].weekday]}</p>
          </>
        ))}
      {checkResult.map((day, key) =>
        day ? (
          <p key={key}>
            {OPENING_FROM +
              " " +
              day.from_time.slice(0, -3) +
              " " +
              OPENING_TO +
              " " +
              day.to_time.slice(0, -3)}
          </p>
        ) : (
          ""
        )
      )}
    </div>
  );
}
