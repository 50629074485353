import React, { createContext, useContext, useEffect, useState } from "react";
import useFetch from "use-http";
import { domain } from "../constants";

const DeliverySectionsContext = createContext();

export default function DeliverySectionsProvider({ children }) {
  const [sections, setSections] = useState([]);
  const { get, response, loading, error } = useFetch(domain);

  useEffect(() => {
    (async () => {
      setTimeout(async () => {
        const fetchedInfo = await get("settings/section_all/");
        if (response.ok) setSections(fetchedInfo);
      }, 2000);
    })();
    // eslint-disable-next-line
  }, []);

  const getSection = (id) => {
    return sections.filter((section) => section["id"] === +id)[0];
  };

  return (
    <DeliverySectionsContext.Provider value={{ sections, loading, error , getSection }}>
      {children}
    </DeliverySectionsContext.Provider>
  );
}

export const useSections = () => useContext(DeliverySectionsContext);
