import React, { useEffect, useState } from "react";
import FormTextArea from "../../partials/FormTextArea";
import ClipLoader from "react-spinners/ClipLoader";
import {
  JOBS_SELECT_PLACEHOLDER,
  JOBS_FORM_TITLE,
  JOBS_PAGE_TITLE,
  JOBS_REFER,
  JOBS_REFER_SELECT_1,
  JOBS_REFER_SELECT_2,
  PERSONAL_INFO_FIRST_NAME,
  PERSONAL_INFO_LAST_NAME,
  JOBS_ADDRESS,
  JOBS_POST_CODE,
  JOBS_BIRTH_DATE,
  JOBS_BIRTH_PLACE,
  JOBS_NATIONALITY,
  JOBS_MARTIAL,
  JOBS_APPLY_AS,
  JOBS_APPLY_AS_PLACEHOLDER,
  JOBS_SECTION_1_TITLE,
  JOBS_TEL,
  JOBS_EMAIL,
  JOBS_OWN_CAR,
  JOBS_YES_OPTION,
  JOBS_NO_OPTION,
  JOBS_DRIVING_LICENSE,
  JOBS_DEPLOYMENT,
  JOBS_LEARNED_JOB,
  JOBS_PAST_JOB,
  JOBS_ADD_NOTE,
  JOBS_CONDITIONS,
  JOBS_SUBMIT,
  JOBS_SUBMITTED_MESSAGE,
  JOBS_NOT_AVAILBLE,
} from "language";
import { domain } from "../../constants";
import { useAuth } from "Context/AuthProvider";
import { TiTickOutline } from "react-icons/ti";

const Jobs = () => {
  const { token } = useAuth();
  const [availableJobs, setAvailableJobs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [disableForm, setDisableForm] = useState(true);
  const [selectedJob, setSelectedJob] = useState(-1);
  const [salutation, setSalutation] = useState(-1);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [street, setStreet] = useState("");
  const [postCode, setPostCode] = useState("");
  const [birthDate, setBirthDate] = useState(new Date());
  const [birthPlace, setBirthPlace] = useState("");
  const [nationality, setNationality] = useState("");
  const [martial, setMartial] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [ownCar, setOwnCar] = useState(false);
  const [drivingLicense, setDrivingLicense] = useState("");
  const [isEmployed, setIsEmployed] = useState(false);
  const [learnedJobs, setLearnedJobs] = useState("");
  const [profession, setProfession] = useState("");
  const [annonation, setAnnonation] = useState("");
  const [submittedJob, setSubmittedJob] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const response = await fetch(`${domain}/settings/availabe_job/`);

      const fetchedJobs = await response.json();
      if (response.ok) {
        setAvailableJobs(fetchedJobs);
      }
      setLoading(false);
    })();

    // eslint-disable-next-line
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();

    const requestBody = {
      salutation: salutation,
      first_name: firstName,
      last_name: lastName,
      street: street,
      postcode: postCode,
      birthdate: birthDate,
      place_of_birth: birthPlace,
      nationality: nationality,
      marital_status: martial,
      phone: phone,
      own_car: ownCar,
      driving_license: drivingLicense,
      is_employed: isEmployed,
      jobs_learnt: learnedJobs,
      profession: profession,
      annotation: annonation,
      email: email,
      job: selectedJob,
    };

    fetch(`${domain}/settings/job/`, {
      method: "POST",
      body: JSON.stringify(requestBody),
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }).then((res) => {
      if (res.ok) {
        setSubmittedJob(true);
        window.scrollTo(0, 0);
      }
    });
  };

  if(!loading && availableJobs.length < 1) return<div className="jobs-page-container">
    <h1 className="__no-jobs">
      {JOBS_NOT_AVAILBLE}
    </h1>
  </div>

  return (
    <>
      {submittedJob ? (
        <div className="job-submitted">
          <TiTickOutline className="__tick" />
          <h1 className="__thank">{JOBS_SUBMITTED_MESSAGE}</h1>
        </div>
      ) : (
        <div className="jobs-page-container">
          <h1 className="__page-title">{JOBS_PAGE_TITLE}</h1>
          {!loading ? (
            <div className="__form-container">
              <form onSubmit={onSubmit} action="">
                <h3 className="__title">{JOBS_FORM_TITLE}</h3>

                <div className="__pair __required">
                  <label htmlFor="job" className="__label">
                    {JOBS_APPLY_AS}
                  </label>
                  <div className="__select-input">
                    <select
                      name="job"
                      id="job"
                      onChange={(job) => {
                        setSelectedJob(parseInt(job.target.value));
                        setDisableForm(false);
                      }}
                      required
                    >
                      <option value="" disabled selected>
                        {JOBS_APPLY_AS_PLACEHOLDER}
                      </option>
                      {availableJobs.map((job) => (
                        <option key={job.id} value={job.id}>
                          {job.job_title}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <h4 className="__section-title">{JOBS_SECTION_1_TITLE}</h4>

                <div className="__pair __required">
                  <label htmlFor="salutation" className="__label">
                    {JOBS_REFER}
                  </label>
                  <div className="__select-input">
                    <select
                      name="salutation"
                      id="salutation"
                      disabled={disableForm}
                      onChange={(salute) =>
                        setSalutation(parseInt(salute.target.value))
                      }
                      required
                    >
                      <option value="" disabled selected>
                        {JOBS_SELECT_PLACEHOLDER}
                      </option>
                      <option value="1">{JOBS_REFER_SELECT_1}</option>
                      <option value="2">{JOBS_REFER_SELECT_2}</option>
                    </select>
                  </div>
                </div>

                <div className="__two-inputs">
                  <div className="__pair __required __half">
                    <label htmlFor="first_name" className="__label">
                      {PERSONAL_INFO_FIRST_NAME}
                    </label>
                    <input
                      className="__input"
                      type="text"
                      id="first_name"
                      name="first_name"
                      disabled={disableForm}
                      onChange={(first_name) =>
                        setFirstName(first_name.target.value)
                      }
                      required
                    />
                  </div>
                  <div className="__pair __required __half">
                    <label htmlFor="last_name" className="__label">
                      {PERSONAL_INFO_LAST_NAME}
                    </label>
                    <input
                      className="__input"
                      type="text"
                      id="last_name"
                      name="last_name"
                      disabled={disableForm}
                      onChange={(last_name) =>
                        setLastName(last_name.target.value)
                      }
                      required
                    />
                  </div>
                </div>

                <div className="__two-inputs">
                  <div className="__pair __required __half">
                    <label htmlFor="street" className="__label">
                      {JOBS_ADDRESS}
                    </label>
                    <input
                      className="__input"
                      type="text"
                      id="street"
                      name="street"
                      disabled={disableForm}
                      onChange={(street) => setStreet(street.target.value)}
                      required
                    />
                  </div>
                  <div className="__pair __required __half">
                    <label htmlFor="postcode" className="__label">
                      {JOBS_POST_CODE}
                    </label>
                    <input
                      className="__input"
                      type="text"
                      id="postcode"
                      name="postcode"
                      disabled={disableForm}
                      onChange={(postcode) =>
                        setPostCode(postcode.target.value)
                      }
                      required
                    />
                  </div>
                </div>

                <div className="__two-inputs">
                  <div className="__pair __required __half">
                    <label htmlFor="birthdate" className="__label">
                      {JOBS_BIRTH_DATE}
                    </label>
                    <input
                      className="__input"
                      id="birthdate"
                      name="birthdate"
                      disabled={disableForm}
                      onChange={(birthdate) =>
                        setBirthDate(birthdate.target.value)
                      }
                      
                      min={new Date()}
                      required
                    />
                  </div>
                  <div className="__pair __required __half">
                    <label htmlFor="place_of_birth" className="__label">
                      {JOBS_BIRTH_PLACE}
                    </label>
                    <input
                      className="__input"
                      type="text"
                      id="place_of_birth"
                      name="place_of_birth"
                      disabled={disableForm}
                      onChange={(birthplace) =>
                        setBirthPlace(birthplace.target.value)
                      }
                      required
                    />
                  </div>
                </div>

                <div className="__pair __required">
                  <label htmlFor="nationality" className="__label">
                    {JOBS_NATIONALITY}
                  </label>
                  <input
                    className="__input"
                    type="text"
                    id="nationality"
                    name="nationality"
                    disabled={disableForm}
                    onChange={(nationality) =>
                      setNationality(nationality.target.value)
                    }
                    required
                  />
                </div>

                <div className="__pair __required">
                  <label htmlFor="marital_status" className="__label">
                    {JOBS_MARTIAL}
                  </label>
                  <input
                    className="__input"
                    type="text"
                    id="marital_status"
                    name="marital_status"
                    disabled={disableForm}
                    onChange={(martial) => setMartial(martial.target.value)}
                    required
                  />
                </div>

                <div className="__two-inputs">
                  <div className="__pair __required __half">
                    <label htmlFor="phone" className="__label">
                      {JOBS_TEL}
                    </label>
                    <input
                      className="__input"
                      type="tel"
                      id="phone"
                      name="phone"
                      disabled={disableForm}
                      onChange={(phone) => setPhone(phone.target.value)}
                      required
                    />
                  </div>
                  <div className="__pair __required __half">
                    <label htmlFor="email" className="__label">
                      {JOBS_EMAIL}
                    </label>
                    <input
                      className="__input"
                      type="email"
                      id="email"
                      name="email"
                      disabled={disableForm}
                      onChange={(email) => setEmail(email.target.value)}
                      required
                    />
                  </div>
                </div>

                <div className="__pair __required">
                  <label htmlFor="own_car" className="__label">
                    {JOBS_OWN_CAR}
                  </label>
                  <div className="__select-input">
                    <select
                      name="own_car"
                      id="own_car"
                      disabled={disableForm}
                      onChange={(own) => setOwnCar(own.target.value === "true")}
                      required
                    >
                      <option value="" disabled selected>
                        {JOBS_SELECT_PLACEHOLDER}
                      </option>
                      <option value={true}>{JOBS_YES_OPTION}</option>
                      <option value={false}>{JOBS_NO_OPTION}</option>
                    </select>
                  </div>
                </div>

                <div className="__pair __required">
                  <label htmlFor="driving_license" className="__label">
                    {JOBS_DRIVING_LICENSE}
                  </label>
                  <input
                    className="__input"
                    type="text"
                    id="driving_license"
                    name="driving_license"
                    disabled={disableForm}
                    onChange={(license) =>
                      setDrivingLicense(license.target.value)
                    }
                    required
                  />
                </div>

                <div className="__pair __required">
                  <label htmlFor="is_employed" className="__label">
                    {JOBS_DEPLOYMENT}
                  </label>
                  <div className="__select-input">
                    <select
                      name="is_employed"
                      id="is_employed"
                      disabled={disableForm}
                      onChange={(is_employed) =>
                        setIsEmployed(is_employed.target.value === "true")
                      }
                      required
                    >
                      <option disabled selected>
                        {JOBS_SELECT_PLACEHOLDER}
                      </option>
                      <option value={true}>{JOBS_YES_OPTION}</option>
                      <option value={false}>{JOBS_NO_OPTION}</option>
                    </select>
                  </div>
                </div>

                <div className="__pair __required">
                  <label htmlFor="jobs_learnt" className="__label">
                    {JOBS_LEARNED_JOB}
                  </label>
                  <input
                    className="__input"
                    type="text"
                    id="jobs_learnt"
                    name="jobs_learnt"
                    disabled={disableForm}
                    onChange={(learned) => setLearnedJobs(learned.target.value)}
                    required
                  />
                </div>

                <div className="__pair __required">
                  <label htmlFor="profession" className="__label">
                    {JOBS_PAST_JOB}
                  </label>
                  <input
                    className="__input"
                    type="text"
                    id="profession"
                    name="profession"
                    disabled={disableForm}
                    onChange={(profession) =>
                      setProfession(profession.target.value)
                    }
                    required
                  />
                </div>

                <div className="__pair">
                  <label htmlFor="annonation" className="__label">
                    {JOBS_ADD_NOTE}
                  </label>
                  <FormTextArea
                    name={"annonation"}
                    required={true}
                    value={annonation}
                    setValue={setAnnonation}
                  />
                </div>

                <p className="__conditions">{JOBS_CONDITIONS}</p>

                <button type="submit" className="__submit">
                  {JOBS_SUBMIT}
                </button>
              </form>
            </div>
          ) : (
            <ClipLoader />
          )}
        </div>
      )}
    </>
  );
};

export default Jobs;
