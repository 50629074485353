import { domain } from "./constants";
import {
  ACCEPTED,
  CANCELLED,
  DELIVERED,
  DELIVERING,
  DELIVERY,
  DINE_IN,
  DONE,
  PENDING,
  PENDING_DELIVERING,
  PENDING_PICKUP,
  PICKUP,
  REJECTED,
} from "./language";

export const price = (value) => {
  return <span className="price">{parseFloat(value).toFixed(2)}</span>;
};
export const newPrice = (value, newValue) => {
  return (
    <div className="new-price-wrapper">
      <span className="__old">{parseFloat(value).toFixed(2)}</span>
      <span className="__new">{parseFloat(newValue).toFixed(2)}</span>
    </div>
  );
};

export function formatOrder(order) {
  const formatted = {
    first_name: order.first_name,
    last_name: order.last_name,
    email: order.email,
    phone: order.phone,
    type: order.type,
    notes: order.notes,
    recieve_email: order.recieve_email,
    coupon: order.coupon,
    items: order.items.map((item) => {
      const _item = {
        meal: item.meal.id,
        quantity: item.quantity,
        notes: item.notes,
        addons: item.addons.map((addon) => ({ addon: addon.id })),
      };
      if (item.size) _item["size"] = item.size.id;
      return _item;
    }),
    payment: order.payment,
  };

  if (order.is_preorder && order.serve_time) {
    formatted["serve_time"] =
      new Date(order.serve_time).toISOString() ?? new Date();
  }

  if (order.type === 1) {
    formatted["delivery"] = {
      address: order.delivery.address,
      zone: order.delivery.section.zone ? order.delivery.section.zone.id : "",
      section: order.delivery.section ? order.delivery.section.id : "",
      building_no: order.delivery.building_no,
      section_name: "",
    };
    if (!order.delivery.building_no) delete formatted.delivery["building_no"];
    if (!order.delivery.address) delete formatted.delivery["address"];
  }
  if (order.type === 3) {
    formatted["dine_table"] = order.dine_table;
    formatted["dine_table_passcode"] = order.table.passcode;
  }
  formatted["gift_choices"] = order.gift_choices;
  return formatted;
}

export function formatStatus(status, type) {
  switch (status) {
    case 1:
      return PENDING;
    case 2:
      return REJECTED;
    case 3:
      return ACCEPTED;
    case 4:
      if (type === 1) {
        return PENDING_DELIVERING;
      } else {
        return PENDING_PICKUP;
      }
    case 5:
      return DELIVERING;
    case 6:
      return DELIVERED;
    case 7:
      return DONE;
    case 8:
      return CANCELLED;

    default:
      break;
  }
}

export function formatType(type) {
  switch (type) {
    case 2:
      return PICKUP;
    case 1:
      return DELIVERY;
    case 3:
      return DINE_IN;

    default:
      break;
  }
}

export const showCash = (order, information) => {
  if (order._dine_in) {
    return information.allow_dinein_cash;
  }
  return information.cash;
};

export const showPaypal = (order, information) => {
  if (order._dine_in) {
    return information.allow_dinein_paypal;
  }
  return information.paypal;
};

export async function verify(order, noPayment) {
  if (!order._accepted_terms) return { ok: false, json: async () => ({}) };
  if (order.is_preorder && (!order.serve_time  || order._invalidServeTime ) )
    return { ok: false, json: async () => ({}) };
  const headers = { "Content-Type": "application/json" };
  if (order.token) {
    headers.Authorization = `Bearer ${order.token}`;
  }
  const endpoint = noPayment
    ? "/orders/order/validate2/"
    : "/orders/order/validate/";
  const response = await fetch(domain + endpoint, {
    method: "POST",
    body: JSON.stringify(formatOrder(order)),
    headers,
  });

  return response;
}

export const disableButton = (id) => {
  try {
    const btn = document.getElementById(id);
    btn.disabled = true;
  } catch {}
};
export const enableButton = (id) => {
  try {
    const btn = document.getElementById(id);
    btn.disabled = false;
  } catch {}
};
export const shakeButton = (id) => {
  try {
    document.getElementById(id).classList.add("animate__animated");
    document.getElementById(id).classList.add("animate__shakeX");

    setTimeout(() => {
      document.getElementById(id).classList.remove("animate__animated");
      document.getElementById(id).classList.remove("animate__shakeX");
    }, 1000);
  } catch {}
};
