import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import useFetch from "use-http";
import { domain } from "../constants";
const AddonsContext = createContext();

export default function AddonsProvider({ children }) {
  const [addons, setAddons] = useState([]);
  const { get, response, loading, error } = useFetch(domain);

  useEffect(() => {
    (async () => {
      const fetchedInfo = await get("/menu/addon/");
      if (response.ok) setAddons(fetchedInfo);
    })();
    // eslint-disable-next-line
  }, []);

  const getAddons = useCallback(
    (groupId, from, groupName) => {
      return addons
        .filter((addon) => groupId === addon["group"])
        .map((addon) => ({ ...addon, from, groupName }));
    },
    [addons]
  );

  return (
    <AddonsContext.Provider value={{ getAddons, loading, error }}>
      {children}
    </AddonsContext.Provider>
  );
}

export const useAddons = () => useContext(AddonsContext);
