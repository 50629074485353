export const INITIAL_ORDER_STATE = {
  first_name: "",
  last_name: "",
  email: "",
  phone: "",
  type: null,
  delivery: {
    address: "",
    zone: 0,
    section: {},
    building_no: "",
    section_name: "",
    zipcode: "",
  },
  payment: {
    type: 1,
  },
  items: [],
  serve_time: '',
  serve_time_time: null,
  serve_time_date: null,
  recieve_email: false,
  notes: "",
  before_price: 0.0,
  total_price: 0.0,
  items_price: 0.0,
  discount_price: 0.0,
  delivery_price: 0.0,
  free_delivery: false,
  is_preorder: false,
  dine_table: null,
  table: null,
  gift_choices: [],
  coupon: "",
  token: "",
  _accepted_terms: false,
  _disallow: true,
  _disallow_editing: false,
  _dine_in: false,
  _loading_price: false,
  _error_price: false,
  _coupon_valid: false,
  _coupon_invalid: false,
  _submittedOrder: false,
  _invalidServeTime : false
};
