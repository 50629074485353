import React, { createContext, useContext, useEffect, useState } from "react";
import useFetch from "use-http";
import { domain } from "../constants";

const MenuContext = createContext();

export default function MenuProvider({ children }) {
  const [menu, setMenu] = useState([]);
  const { get, response, loading, error } = useFetch(domain);

  useEffect(() => {
    (async () => {
      const fetchedInfo = await get("menu/");
      if (response.ok) setMenu(fetchedInfo);
    })();
    // eslint-disable-next-line
  }, []);

  const getAllMeals = () => {
    const meals = [];
    menu.forEach((category) => {
      category.meals.forEach((meal) => meals.push(meal));
    });
    return meals;
  };



  return (
    <MenuContext.Provider
      value={{ menu, loading, error, meals: getAllMeals() }}
    >
      {children}
    </MenuContext.Provider>
  );
}

export const useMenu = () => useContext(MenuContext);
