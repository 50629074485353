import React from "react";
import { Redirect, Route } from "react-router";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { useAuth } from "../Context/AuthProvider";

export default function GuestRoute({ component, ...props }) {
  const { isLoggedIn } = useAuth();
  const location = useLocation();
  return (
    <Route
      {...props}
      render={() => {
        if (!isLoggedIn) {
          return component;
        } else {
          return <Redirect to={location.state ? location.state.referrer : '/menu'}></Redirect>;
        }
      }}
    ></Route>
  );
}
