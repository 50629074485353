import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";
import { domain } from "../../constants";
import { formatOrder } from "../../utils";
import { INITIAL_ORDER_STATE } from "./initialOrderState";
import Cookies from "js-cookie";

const storedOrder = getFromLocalStorage();
export const fetchPrice = createAsyncThunk(
  "order/fetchPrice",
  async (order, thunkApi) => {
    const formattedOrder = formatOrder(order);
    
    const reqBody = {
      items: formattedOrder.items,
      coupon: formattedOrder.coupon,
    };
    if (formattedOrder.delivery) {
      reqBody.delivery = formattedOrder.delivery;
    }
    const headers = {
      "Content-Type": "application/json",
    };
    if (order.token) headers["Authorization"] = `Bearer ${order.token}`;
    return fetch(domain + "/orders/order/calc_total_price/", {
      method: "POST",
      body: JSON.stringify(reqBody),
      headers,
    })
      .then((resp) => {
        if (resp.ok) return resp.json();
        return { errorFetchingPrice: resp.status };
      })
      .catch((err) => console.log({ err }));
  }
);

export const orderSlice = createSlice({
  name: "order",
  initialState: storedOrder ? storedOrder : INITIAL_ORDER_STATE,
  reducers: {
    addItem: (state, action) => {
      state.items = [...state.items, { ...action.payload, id: uuidv4() }];
      handleOrderChange(state);
    },
    acceptTerms: (state, { payload }) => {
      state._accepted_terms = payload;
      handleOrderChange(state);
    },
    removeItem: (state, action) => {
      state.items = state.items.filter((item) => item.id !== action.payload.id);
      handleOrderChange(state);
    },
    editItem: (state, action) => {
      state.items.forEach((item, index) => {
        if (item.id === action.payload.id) {
          state.items[index] = { ...item, ...action.payload };
        }
      });
      handleOrderChange(state);
    },
    setAttribs: (state, action) => {
      const attribs = action.payload;
      for (let i = 0; i < attribs.length; i++) {
        let attrib = attribs[i];
        if (state[attrib.name] !== undefined && attrib.name !== "items")
          state[attrib.name] = attrib.value;
      }

      handleOrderChange(state);
    },
    clearDelivery: (state) => {
      state.delivery = {
        zone: 0,
        section: {},
      };
      handleOrderChange(state);
    },
    clearOrder: (state) => {
      clearOrderFromLocalSroage();
      return INITIAL_ORDER_STATE;
    },
    clearPreorder: (state) => {
      state.is_preorder = false;
      state.serve_time = "";
      state.serve_time_time = null;
      state.serve_time_date = null;
      handleOrderChange(state);
    },
  },
  extraReducers: {
    [fetchPrice.fulfilled]: (state, { payload }) => {
      if (payload.errorFetchingPrice) {
        state._error_price = true;
      } else {
        state.items_price = parseFloat(payload.before_price).toFixed(2);
        state.total_price = parseFloat(payload.total_price).toFixed(2);
        state.discount_price = parseFloat(payload.discount_price).toFixed(2);
        state.delivery_price = parseFloat(payload.delivery_price).toFixed(2);
        state.free_delivery = !(parseFloat(payload.delivery_price) > 0);
        state._error_price = false;
        state._loading_price = false;
        state._coupon_valid = state.coupon !== "" && payload.coupon !== null;
        state._coupon_invalid = state.coupon !== "" && payload.coupon === null;
      }
    },
    [fetchPrice.pending]: (state, { payload }) => {
      state._loading_price = true;
    },
    [fetchPrice.rejected]: (state, payload) => {
      state._loading_price = false;
    },
  },
});

export default orderSlice.reducer;

// Actions
export const {
  addItem,
  removeItem,
  editItem,
  setAttribs,
  clearDelivery,
  clearOrder,
  clearPreorder,
  acceptTerms,
} = orderSlice.actions;

// Selectors
export const selectOrder = (state) => state.order;
export const selectTotalPrice = (state) => state.order.total_price;
export const selectItemsPrice = (state) => state.order.items_price;
export const selectFreeDelivery = (state) => state.order.free_delivery;
export const selectItems = (state) => state.order.items;
export const selectItemsLength = (state) => state.order.items.length;
export const selectDelivery = (state) => state.order.delivery;
export const selectType = (state) => state.order.type;
export const selectIsPreorder = (state) => state.order.is_preorder;
export const selectServeTime = (state) => state.order.serve_time;

function handleOrderChange(order) {
  const consent = Cookies.get("CookieConsent");
  if (consent === "true") {
    addOrderToLocalStorage({ ...order, cart_last_update: Date.now() });
  }
}
function clearOrderFromLocalSroage() {
  window.localStorage.removeItem("my-order");
}
function addOrderToLocalStorage(order) {
  window.localStorage.setItem("my-order", JSON.stringify(order));
}

function getFromLocalStorage() {
  const consent = Cookies.get("CookieConsent");
  if (consent !== "true") return INITIAL_ORDER_STATE;
  const _order = JSON.parse(window.localStorage.getItem("my-order"));
  if (!_order) {
    return INITIAL_ORDER_STATE;
  }
  
  const last_update = new Date(_order.cart_last_update);
  
  if (isValidCart(last_update)) {
    return _order;
  } else {
    clearOrderFromLocalSroage();
    return INITIAL_ORDER_STATE;
  }
}

function isValidCart(last_update) {
  const now = new Date(Date.now());
  const diff = Math.round((now.getTime() - last_update.getTime()) / 1000 / 60);
  return diff < 15;
}
