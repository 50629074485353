import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useDispatch } from "react-redux";
import useFetch from "use-http";
import { domain } from "../constants";
import { setAttribs } from "../globals/slices/OrderSlice";
const AuthContext = createContext();

export default function AuthProvider({ children }) {
  // eslint-disable-next-line
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [token, setToken] = useState("");
  const [currentUser, setCurrentUser] = useState(null);
  const { post, response, error } = useFetch(domain);

  const register = useCallback(
    async (body) => {
      const jsonResponse = await post("/auth/users/", body);
      return { response, jsonResponse };
    },
    // eslint-disable-next-line
    [post]
  );

  const confirm = useCallback(
    async (body) => {
      await post("/auth/users/activation/", body);
      return { response };
    },
    // eslint-disable-next-line
    [post]
  );
  const resendConfirmation = useCallback(
    async (body) => {
      await post("/auth/users/resend_activation/", body);
    },
    // eslint-disable-next-line
    [post]
  );
  const resetPassword = useCallback(
    async (body) => {
      await post("/auth/users/reset_password/", body);
    },
    // eslint-disable-next-line
    [post]
  );
  const deleteReview = useCallback(
    async () => {
      return await fetch(`${domain}/users/user_review/destroyy/`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    },
    // eslint-disable-next-line
    [token]
  );
  const resetPasswordConfirm = useCallback(
    async (body) => {
      await post("/auth/users/reset_password_confirm/", body);
      return { response, error };
    },
    // eslint-disable-next-line
    [post]
  );

  const login = useCallback(
    async (body) => {
      const tokens = await post("/token/", body);
      if (response.ok) {
        if (body.remember) {
          window.localStorage.setItem("access", tokens["access"]);
        }
        setIsLoggedIn(true);
        setToken(tokens["access"]);
      }
      return { error, response };
    },
    // eslint-disable-next-line
    [post]
  );

  const updateUserInfoLocally = useCallback(
    async (body) => {
      setCurrentUser((prev) => ({ ...prev, [body.key]: body.value }));
    },
    // eslint-disable-next-line
    []
  );

  const logout = useCallback(() => {
    window.localStorage.removeItem("access");
    setIsLoggedIn(false);
    setToken("");
    setCurrentUser(null);
  }, []);

  useEffect(() => {
    const access = window.localStorage.getItem("access");
    if (access) {
      post("/token/verify/", { token: access }).then(() => {
        if (response.ok) {
          setIsLoggedIn(true);
          setToken(access);
        } else {
          setIsLoggedIn(false);
          window.localStorage.removeItem("access");
        }
      });
    }
    // eslint-disable-next-line
  }, []);

  const dispatch = useDispatch();

  const getCurrentUser = async () => {
    let jsonResponse = await fetch(domain + "/auth/users/me/", {
      mode: "cors",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    jsonResponse = await jsonResponse.json();
    setCurrentUser(jsonResponse);
  };

  useEffect(() => {
    if (isLoggedIn && token && !currentUser) {
      getCurrentUser();
      dispatch(
        setAttribs([
          {
            name: "token",
            value: token,
          },
        ])
      );
    }
    // eslint-disable-next-line
  }, [isLoggedIn, token]);

  return (
    <AuthContext.Provider
      value={{
        register,
        confirm,
        resendConfirmation,
        login,
        logout,
        resetPassword,
        resetPasswordConfirm,
        updateUserInfoLocally,
        getCurrentUser,
        deleteReview,
        currentUser,
        token,
        isLoggedIn,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export const useAuth = () => useContext(AuthContext);
