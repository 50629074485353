import React, { createContext, useContext, useEffect, useState } from "react";
import useFetch from "use-http";
import { domain } from "../constants";
const InfromationContext = createContext();

export default function InfromationProvider({ children }) {
  const [information, setInformation] = useState({});
  const { get, response, loading, error } = useFetch(domain);


  const [seoPages, setSeoPages] = useState([])

  useEffect(() => {
    (async () => {
      const fetchedInfo = await get("settings/restaurant/1/");
      if (response.ok) setInformation(fetchedInfo);
    })();
    (async () => {
      const fetchedInfo = await get("settings/seo_page/");
      if (response.ok) setSeoPages(fetchedInfo);
    })();
    // eslint-disable-next-line
  }, []);

  return (
    <InfromationContext.Provider value={{ information,seoPages, loading, error }}>
      {children}
    </InfromationContext.Provider>
  );
}

export const useInfromation = () => useContext(InfromationContext);
