import { domain } from "../constants";
import { useEffect } from "react";
import { clearOrder } from "globals/slices/OrderSlice";
import { useDispatch } from "react-redux";

export default function MaintenanceWatcher({ setMaintenanceMode }) {
  const dispatch = useDispatch();
  function check() {
    fetch(domain + `/settings/maintenance_check/`)
      .then((res) => {
        return res.json();
      })
      .then((result) => {
        if (result === 1) {
          localStorage.clear();
          dispatch(clearOrder());
        }
        setMaintenanceMode(result);
      });
  }

  useEffect(() => {
    check();
    let poller = setInterval(() => {
      check();
    }, 5000);
    return () => {
      clearInterval(poller);
    };
    // eslint-disable-next-line
  }, []);

  return null;
}
