import { domain } from "../constants";
import { clearOrder } from "globals/slices/OrderSlice";
import { CALL_WAITER, CLOSE, SCAN_MESSAGE } from "language";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import AlertMessage from "./AlertMessage";
import { disableButton, enableButton } from "utils";

export default function DineInBar() {
  const table = useSelector((state) => state.order.table);
  const dispatch = useDispatch();
  const history = useHistory();

  const callWiater = () => {
    disableButton("call-waiter");

    fetch(domain + "/orders/order/call_waiter/", {
      method: "post",
      body: JSON.stringify({
        table_id: table.id,
        passcode: table.passcode,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    setTimeout(() => {
        enableButton('call-waiter')
    } , 2 * 60 * 1000)
  };

  return (
    <AlertMessage
      message={
        <div className="dine-in-alert-message">
          <p className="__message">
            {SCAN_MESSAGE + table.name + " - " + table.number}
          </p>

          <div className="__btns">
            <button
              onClick={() => {
                dispatch(clearOrder());
                history.push("/");
              }}
              className="btn clear"
            >
              {CLOSE}
            </button>

            <button
              onClick={() => {
                callWiater();
              }}
              className="btn"
              id='call-waiter'
            >
              {CALL_WAITER}
            </button>
          </div>
        </div>
      }
    />
  );
}
