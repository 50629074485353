import { domain } from "../../constants";
import {
  CHECKOUT_TERMS_ACCEPTANCE_2,
  ERROR,
  LINK_AGREEMENT,
  LINK_PRIVACY,
  LINK_RETURN,
  PERSONAL_INFO_EMAIL,
  PERSONAL_INFO_FIRST_NAME,
  PERSONAL_INFO_LAST_NAME,
  PERSONAL_INFO_PHONE,
  RESERVATION_TERMS_ACCEPTANCE_1,
  SUBMIT_RESERVATION,
  SUCCESS_SCREEN_RETURN_TO_HOME,
  SUCCESS_SCREEN_TABLE_RES,
  SUCCESS_SCREEN_THANKS,
  TABLE_RES_DATE,
  TABLE_RES_DATE_REQUIRED,
  TABLE_RES_HEADING,
  TABLE_RES_PERSONS,
} from "language";
import FormInput from "partials/FormInput";
import ValidationError from "partials/ValidationError";
import React, { useEffect, useState } from "react";
import { TiTickOutline } from "react-icons/ti";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useInfromation } from "Context/InfromationProvider";
import FormCheckbox from "partials/FormCheckbox";
import ModalLink from "partials/ModalLink";
import FormSelect from "partials/FormSelect";
import DateTimeSelector from "partials/DateTimeSelector";

export default function Reservation({ inHome = false }) {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [persons, setPersons] = useState(0);
  const [datetime, setDatetime] = useState(null);

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const { information, loading: infoLoading } = useInfromation();
  const [invalidDate, setInvalidDate] = useState(true);

  const history = useHistory();

  useEffect(() => {
    if (!infoLoading && !information.table_reservation) {
      history.push("/");
    }
  }, [history, information, infoLoading]);

  const [maxServeTime, setMaxServeTime] = useState(new Date());

  useEffect(() => {
    if (information.max_days_table) {
      setMaxServeTime(
        new Date(
          maxServeTime.setDate(
            maxServeTime.getDate() + information.max_days_table - 1
          )
        )
      );
    }

    // eslint-disable-next-line
  }, [information]);

  const onSubmit = (e) => {
    e.preventDefault();
    if (!datetime) {
      setError(TABLE_RES_DATE_REQUIRED);
      return;
    }
    setError("");
    const reservation = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      phone: phone,
      datetime: datetime,
      persons_count: persons,
    };

    setLoading(true);
    fetch(domain + "/orders/table_reservation/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(reservation),
    }).then((response) => {
      setLoading(false);
      setSuccess(response.ok);
      setError(!response.ok ? ERROR : "");
    });
  };

  const [acceptUserAgreement, setAcceptUserAgreement] = useState(false);

  const setServeTime = ({ value, ok }) => {
    if (isNaN(value.getTime())) return;
    setInvalidDate(!ok);
    setDatetime(value.toISOString());
  };

  if (!information.table_reservation) return null;

  if (success) {
    return (
      <div className="success-screen">
        <TiTickOutline className="__tick" />
        <h1 className="__thank">{SUCCESS_SCREEN_THANKS}</h1>
        <p className="__message">{SUCCESS_SCREEN_TABLE_RES}</p>

        {!inHome && (
          <p className="__return">
            <Link to="/">{SUCCESS_SCREEN_RETURN_TO_HOME}</Link>
          </p>
        )}
      </div>
    );
  }

  return (
    <div className="reservation">
      <h1 className="__header">{TABLE_RES_HEADING}</h1>

      <form onSubmit={onSubmit} className="__form">
        <fieldset disabled={information.is_super_closed}>
          {error && <ValidationError error={error} />}
          <FormInput
            required={true}
            name={PERSONAL_INFO_FIRST_NAME}
            placeholder={PERSONAL_INFO_FIRST_NAME}
            value={firstName}
            setValue={setFirstName}
          />
          <FormInput
            required={true}
            name={PERSONAL_INFO_LAST_NAME}
            placeholder={PERSONAL_INFO_LAST_NAME}
            value={lastName}
            setValue={setLastName}
          />
          <FormInput
            required={true}
            name={PERSONAL_INFO_EMAIL}
            placeholder={PERSONAL_INFO_EMAIL}
            value={email}
            type="email"
            setValue={setEmail}
          />
          <FormInput
            required={true}
            name={PERSONAL_INFO_PHONE}
            placeholder={PERSONAL_INFO_PHONE}
            value={phone}
            setValue={setPhone}
          />

          <FormSelect
            required={true}
            name={TABLE_RES_PERSONS}
            placeholder={TABLE_RES_PERSONS}
            defaultValue={persons}
            onChange={({ target }) => setPersons(target.value)}
            type="number"
            options={[...Array(information.max_num_table)].map((e, i) => i + 1)}
            optionContent={(option) => option}
          />

          <DateTimeSelector
            callback={setServeTime}
            dateLabel={TABLE_RES_DATE}
            maxDate={maxServeTime}
          />
          <div className="__confirm-info">
            <FormCheckbox
              name={RESERVATION_TERMS_ACCEPTANCE_1}
              label={
                <div className="__accept-terms">
                  {RESERVATION_TERMS_ACCEPTANCE_1}
                  <ModalLink
                    name={LINK_AGREEMENT}
                    content={information["user_agreement"]}
                  />
                  <ModalLink
                    name={LINK_RETURN}
                    content={information["return_policy"]}
                  />
                  {CHECKOUT_TERMS_ACCEPTANCE_2}
                  <ModalLink
                    name={LINK_PRIVACY}
                    content={information["privacy_policy"]}
                  />
                </div>
              }
              value={acceptUserAgreement}
              setValue={setAcceptUserAgreement}
              required={true}
            />
          </div>
          <button
            disabled={!acceptUserAgreement || loading || invalidDate}
            className="btn"
          >
            {SUBMIT_RESERVATION}
          </button>
        </fieldset>
      </form>
    </div>
  );
}
