import React, { useState } from "react";
import Modal from "react-responsive-modal";

export default function ModalLink({ name = "", content, children }) {
  const [open, setOpen] = useState(false);
  const onOpen = (e) => {
    e.stopPropagation();
    neutralizeBack();
  };
  const onClose = () => revivalBack();
  const neutralizeBack = () => {
    setOpen(true);
    window.history.pushState(null, "", window.location.href);
    window.onpopstate = () => {
      window.history.pushState(null, "", window.location.href);
      onClose();
    };
  };
  const revivalBack = () => {
    window.onpopstate = undefined;
    var isSafari = /Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor);
    if (!isSafari) {
      window.history.back();
    }
    setOpen(false);
  };

  function createMarkup() {
    return { __html: content };
  }

  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          onClose();
        }}
        center
        classNames={{
          modal: "modal",
        }}
        focusTrapped={false}
      >
        <div className="__text-content">
          <h2>{name}</h2>
          <div
            dangerouslySetInnerHTML={createMarkup()}
            className="unreset"
          ></div>
        </div>
      </Modal>
      <li className="__link-container">
        <span onClick={onOpen} className="__link">
          {name}
          {children}
        </span>
      </li>
    </>
  );
}
