import React, { createContext, useContext, useEffect, useState } from "react";
import useFetch from "use-http";
import { domain } from "../constants";
const OffersContext = createContext();

export default function OffersProvider({ children }) {
  const [dailyOffers, setDailyOffers] = useState([]);
  const [orderGifts, setOrderGifts] = useState([]);
  const [allOffers, setOffers] = useState([]);
  const { get, response, loading, error } = useFetch(domain);

  useEffect(() => {
    (async () => {
      const fetchedInfo = await get("/menu/daily_offer/today/");
      if (response.ok) setDailyOffers(fetchedInfo);
    })();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    (async () => {
      const fetchedInfo = await get("/menu/all_offers/");
      if (response.ok) setOffers(fetchedInfo);
    })();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    (async () => {
      const fetchedInfo = await get("/settings/order_gift/");
      if (response.ok) setOrderGifts(fetchedInfo);
    })();
    // eslint-disable-next-line
  }, []);

  return (
    <OffersContext.Provider
      value={{ allOffers, dailyOffers, orderGifts ,loading, error }}
    >
      {children}
    </OffersContext.Provider>
  );
}

export const useOffers = () => useContext(OffersContext);
