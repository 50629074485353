import React from "react";
import { FaTimes } from "react-icons/fa";
import { HashLink as Link } from "react-router-hash-link";
import { useAuth } from "../../Context/AuthProvider";
import { useInfromation } from "../../Context/InfromationProvider";
import { FaGooglePlay, FaAppStore } from "react-icons/fa";
import {
  MENU_LINK,
  TRACK_ORDER_LINK,
  ORDER_BUTTON,
  LINK_PRIVACY,
  LINK_AGREEMENT,
  LINK_IMPRESSUM,
  LINK_RETURN,
  LOGIN,
  ACCOUNT,
  REVIEWS_PAGE_TITLE,
  HERO_PLAY_STORE,
  HERO_GET_APP,
  HERO_APP_STORE,
  TABLE_RES,
} from "../../language";
import ModalLink from "../ModalLink";

const Sidebar = ({ toggle }) => {
  const { information, loading } = useInfromation();
  const { isLoggedIn } = useAuth();
  if (loading) {
    return <></>;
  }
  return (
    <>
      <aside className="SidebarContainer">
        <div className="Icon">
          <FaTimes className="CloseIcon" onClick={toggle} />
        </div>
        <div className="SidebarWrapper">
          <ul className="SidebarMenu">
            <Link className="SidebarLink" to="/menu#root" onClick={toggle}>
              {MENU_LINK}
            </Link>
            <Link className="SidebarLink" to="/track#root" onClick={toggle}>
              {TRACK_ORDER_LINK}
            </Link>
            {information.allow_users && (
              <Link className="SidebarLink" to="/reviews#root" onClick={toggle}>
                {REVIEWS_PAGE_TITLE}
              </Link>
            )}
            {information.table_reservation && (
              <Link
                className="SidebarLink"
                to="/reservation#root"
                onClick={toggle}
              >
                {TABLE_RES}
              </Link>
            )}
            {information.allow_users && !isLoggedIn && (
              <Link className="SidebarLink" to="/login#root" onClick={toggle}>
                {LOGIN}
              </Link>
            )}
            {isLoggedIn && (
              <Link className="SidebarLink" to="/account#root" onClick={toggle}>
                {ACCOUNT}
              </Link>
            )}
          </ul>
          <div className="SideBtnWrap">
            <Link className="SidebarRoute" to="/menu" onClick={toggle}>
              {ORDER_BUTTON}
            </Link>

            <ul>
              <ModalLink
                name={LINK_PRIVACY}
                content={information.privacy_policy}
              />
              <ModalLink
                name={LINK_AGREEMENT}
                content={information.user_agreement}
              />
              <ModalLink name={LINK_IMPRESSUM} content={information.imprint} />
              <ModalLink
                name={LINK_RETURN}
                content={information.return_policy}
              />
            </ul>
          </div>
          {(information.social_links[4] || information.social_links[5]) && (
            <div className="__stores-container">
              <h3 className="__title">{HERO_GET_APP}</h3>
              {information.social_links[4] && (
                <div className="__store">
                  <a
                    href={information.social_links[4].url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FaGooglePlay />
                    <p>{HERO_PLAY_STORE}</p>
                  </a>
                </div>
              )}
              {information.social_links[5] && (
                <div className="__store">
                  <a
                    href={information.social_links[4].url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FaAppStore />
                    <p>{HERO_APP_STORE}</p>
                  </a>
                </div>
              )}
            </div>
          )}
        </div>
      </aside>
    </>
  );
};

export default Sidebar;
