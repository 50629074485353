import React from "react";
import AddonsProvider from "./AddonsProvider";
import AuthProvider from "./AuthProvider";
import DeliverySectionsProvider from "./DeliverySectionsProvider";
import InfromationProvider from "./InfromationProvider";
import MenuProvider from "./MenuProvider";
import OffersProvider from "./OffersProvider";
import OpeningProvider from "./OpeneingProvider";
import ScheduleProvider from "./ScheduleProvider";
export default function Context({ children }) {
  return (
    <InfromationProvider>
      <AuthProvider>
        <OpeningProvider>
          <MenuProvider>
            <AddonsProvider>
              <OffersProvider>
                <DeliverySectionsProvider>
                    <ScheduleProvider>{children}</ScheduleProvider>
                </DeliverySectionsProvider>
              </OffersProvider>
            </AddonsProvider>
          </MenuProvider>
        </OpeningProvider>
      </AuthProvider>
    </InfromationProvider>
  );
}
