import { useInfromation } from "Context/InfromationProvider";
import { ORDER_BUTTON } from "language";
import React, { useEffect, useState } from "react";
import { HashLink } from "react-router-hash-link";

export default function SEO({ match }) {
  const { slug } = match.params;

  const { seoPages } = useInfromation();

  const [seoPage, setSeoPage] = useState({});

  useEffect(() => {
    setSeoPage(seoPages.find((page) => page.slug === slug));
  }, [seoPages, slug]);

  return (
    <div className="seo-page">

      <HashLink className="__btn " to="/menu#top">
        <button className="btn order-now"> {ORDER_BUTTON}</button>
      </HashLink>
      <h1>{seoPage && seoPage.title}</h1>
      <p>{seoPage && seoPage.body}</p>
    </div>
  );
}
