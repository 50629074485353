import React from "react";
import { useInfromation } from "../Context/InfromationProvider";
import { HashLink } from "react-router-hash-link";

import {
  DEVELOPER_NAME,
  DEVELOPER_URL,
  JOBS_FOOTER_LINK,
  LINK_AGREEMENT,
  LINK_IMPRESSUM,
  LINK_PRIVACY,
  LINK_RETURN,
  LOGIN,
  MENU_LINK,
  ORDER_BUTTON,
  POWERED_BY,
  REVIEWS_PAGE_TITLE,
  TABLE_RES,
  TRACK_ORDER_LINK,
} from "../language";
import ModalLink from "./ModalLink";
import { host } from "../constants";
import { useAuth } from "../Context/AuthProvider";
import SocialLinks from "./SocialLinks";
const Footer = () => {
  const { information, seoPages } = useInfromation();
  const date = new Date(Date.now());
  const { isLoggedIn } = useAuth();

  return (
    <div className="footer-container">
      <div className="__footer">
        <div className="__main-column">
          <img className="__logo" src={host + information["logo"]} alt="logo" />
          <h1 className="__title">{information["name"]}</h1>

          <HashLink className="__btn " to="/menu#top">
            <button className="btn "> {ORDER_BUTTON}</button>
          </HashLink>
          <SocialLinks />
        </div>

        <div className="__column">
          <ul className="__links">
            <li className="__item">
              <HashLink className="__link" to="/menu#root">
                {MENU_LINK}
              </HashLink>
            </li>
            <li className="__item">
              <HashLink className="__link" to="/track#root">
                {TRACK_ORDER_LINK}
              </HashLink>
            </li>
            {information.allow_user_reviews && information.allow_users && (
              <li className="__item">
                <HashLink className="__link" to="/reviews#root">
                  {REVIEWS_PAGE_TITLE}
                </HashLink>
              </li>
            )}

            {information.table_reservation && (
              <li className="__item">
                <HashLink className="__link" to="/reservation#root">
                  {TABLE_RES}
                </HashLink>
              </li>
            )}
            {information.allow_users && !isLoggedIn && (
              <li className="__item">
                <HashLink className="__link" to="/login#root">
                  {LOGIN}
                </HashLink>
              </li>
            )}
            {information.allow_jobs && (
              <li className="__item">
                <HashLink className="__link" to="/jobs#root">
                  {JOBS_FOOTER_LINK}
                </HashLink>
              </li>
            )}
          </ul>
        </div>
        <div className="__column">
          <ul className="__links">
            <ModalLink
              name={LINK_PRIVACY}
              content={information["privacy_policy"]}
            />
            <ModalLink
              name={LINK_AGREEMENT}
              content={information["user_agreement"]}
            />
            <ModalLink name={LINK_IMPRESSUM} content={information["imprint"]} />
            <ModalLink
              name={LINK_RETURN}
              content={information["return_policy"]}
            />
          </ul>
        </div>
      </div>
      <div className="__end-banner">
        <div className="__copyright">
          <p>
            {information["name"]} © {date.getFullYear()}
          </p>
        </div>
      </div>
      <div className="__seo-pages">
        <ul>
          {seoPages.map((page) => (
            <li key={page.slug}>
              <a href={`${window.origin}/seo/${page.slug}`}>{page.title}</a>
            </li>
          ))}
        </ul>
      </div>

      <div className="__end-banner">
        <div className="__orderset">
          <h2>{POWERED_BY}</h2>
          <a href={DEVELOPER_URL} className="__a">
            <img
              alt={DEVELOPER_NAME}
              className="__img"
              src={require("../assets/orderset.png").default}
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
