import React from "react";
import { GoAlert } from "react-icons/go";

export default function AlertMessage({ message, variant = "" }) {
  return (
    <div className={`alert-message --${variant}`}>
      {variant === "error" && <GoAlert className="__icon" />}
      {message}
    </div>
  );
}
