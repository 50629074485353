import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { Application } from "react-rainbow-components";
import useFetch from "use-http";
import { domain } from "./constants";

const ThemeContext = createContext();

export default function ThemeProvider({ children }) {
  const { get, response } = useFetch(domain);

  const [themes, setThemes] = useState([]);
  const [reactRainbowTheme, setReactRainbowTheme] = useState({});

  useEffect(() => {
    (async () => {
      const fetchedInfo = await get("menu/theme/");
      if (response.ok) setThemes(fetchedInfo);
    })();
    // eslint-disable-next-line
  }, []);

  const getColorValue = useCallback(
    (name) => {
      try {
        return "#" + themes.find((color) => color.name === name).value;
      } catch (err) {
        return "";
      }
    },
    [themes]
  );

  useEffect(() => {
    if (themes.length > 0) {
      const rootElemenet = document.documentElement;
      rootElemenet.style.setProperty(
        "--color-primary",
        getColorValue("primary")
      );
      rootElemenet.style.setProperty(
        "--color-dark-primary",
        getColorValue("dark-primary")
      );
      rootElemenet.style.setProperty(
        "--color-secondary",
        getColorValue("secondary")
      );
      rootElemenet.style.setProperty(
        "--color-dark-secondary",
        getColorValue("dark-secondary")
      );
      rootElemenet.style.setProperty(
        "--color-light-secondary",
        getColorValue("light-secondary")
      );

      rootElemenet.style.setProperty(
        "--color-light-background",
        getColorValue("light-background")
      );
      rootElemenet.style.setProperty(
        "--color-dark-text",
        getColorValue("dark-text")
      );
      rootElemenet.style.setProperty(
        "--color-light-text",
        getColorValue("light-text")
      );
      rootElemenet.style.setProperty(
        "--color-offers-background",
        getColorValue("offers-background")
      );
      rootElemenet.style.setProperty(
        "--color-buttons",
        getColorValue("buttons-color")
      );
      rootElemenet.style.setProperty(
        "--color-category-box-1",
        getColorValue("category-box-1")
      );
      rootElemenet.style.setProperty(
        "--color-category-box-2",
        getColorValue("category-box-2")
      );
    }

    setReactRainbowTheme({
      rainbow: {
        palette: {
          brand: getColorValue("primary")
            ? getColorValue("primary")
            : "#5c56b6",
        },
      },
    });
  }, [themes, getColorValue]);

  return (
    <ThemeContext.Provider value={{ getColorValue }}>
      <Application theme={reactRainbowTheme}>{children}</Application>
    </ThemeContext.Provider>
  );
}

export const useTheme = () => useContext(ThemeContext);

/* rootElemenet.style.setProperty("--color-primary", "#b02121");
rootElemenet.style.setProperty("--color-dark-primary", "#881b1b");
rootElemenet.style.setProperty("--color-secondary", "#f4e285");
rootElemenet.style.setProperty("--color-dark-secondary", "#f1d755");
rootElemenet.style.setProperty("--color-light-secondary", "#fff4bc");

rootElemenet.style.setProperty("--color-light-background", "#fff");
rootElemenet.style.setProperty("--color-dark-text", "#000");
rootElemenet.style.setProperty("--color-light-text", "#fff"); */
