import React, { useEffect, useState } from "react";

export default function FormTextArea({
  name,
  value = "",
  setValue,
  placeholder,
  maxLength = 128,
  required = false,
}) {
  const [localValue, setLocalValue] = useState('');
  useEffect(() => {
    setLocalValue(value);
  }, [value]);
  return (
    <div className="input">
      <textarea
        placeholder={placeholder}
        id={name}
        value={localValue}
        onChange={({ target }) => {
          setLocalValue(target.value);
          setValue(target.value);
        }}
        maxLength={maxLength}
        required={required}
      ></textarea>
    </div>
  );
}
