import React, { createContext, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchPrice,
  setAttribs,
} from "../globals/slices/OrderSlice";


const OrderContext = createContext();

export default function OrderProvider({ children }) {
  const order = useSelector((state) => state.order);

  const [submittedOrder, setSubmittedOrder] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (order.items.length > 0) {
      dispatch(fetchPrice(order));
    }
    // eslint-disable-next-line
  }, [order.items, order.delivery, dispatch, order.coupon, order.token]);
  useEffect(() => {
    dispatch(
      setAttribs([
        {
          name: "gift_choices",
          value: [],
        },
      ])
    );
    // eslint-disable-next-line
  }, [order.total_price]);


  return (
    <OrderContext.Provider value={{ submittedOrder, setSubmittedOrder }}>
      {children}
    </OrderContext.Provider>
  );
}

export const useOrder = () => useContext(OrderContext);
