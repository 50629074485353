import React from "react";

export default function FormSelect({
  disabled = false,
  name = "",
  onChange = () => { },
  options = [],
  optionContent = () => "",
  key = "id",
  initialValue,
  placeholder,
  disableCondition = () => false
}) {
  
  return (
    <fieldset disabled={disabled} className="input">
      <label htmlFor={name}>{name}:</label>
      <select
        required={true}
        defaultValue={initialValue ? initialValue : null}
        onChange={onChange}
        id={name}
      >
        <option value='' hidden>{placeholder || name}...</option>
        {options.map((option) => (
          <option disabled={disableCondition(option)} key={option[key]} value={option[key]}>
            {optionContent(option)}
          </option>
        ))}
      </select>
    </fieldset>
  );
}
